import React from 'react';
import Skills from '../components/Skills/skills';
import Gallery from '../components/Gallery/gallery';
import About from './about';
import './resume.scss';

import resume from '../images/Pablo_Torres_Resume.jpg'

function Work() {
    return (
        <div className='resume-container'>
            {/* <div className='intro row'> 
                <div className='greeting'>
                    <h1>👋🏽 Hi! <br/> 
                    My name is <br/> 
                    Pablo Torres!</h1>
                    </div>
                <div className='details'>
                    <p className='large'>
                        👨🏽‍💻 Frontend Developer. <br/>
                        📍 Based in ATX.
                    </p>
                </div>
            </div> */}
            {/* No Gallery to display for now */}
            {/* <div className='gallery row'>
                <Gallery/>
            </div> */}
            {/* <div className='skills row'>
                <Skills/>
            </div> */}
            <div className='resume'>
                <img src={resume} id={'resume'}></img>
            </div>
        </div>
    );
}

export default Work;