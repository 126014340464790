import React from 'react';
import pfp from '../images/pfp.jpg'
import Skills from '../components/Skills/skills';
import resume from '../images/Pablo_Torres_Resume.jpg'
import './about.scss';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function About() {
    const downloadResume = () => {
        const resumeContainer = document.querySelector('.resume-pic');
    
        html2canvas(resumeContainer).then((canvas) => {
          const imgData = canvas.toDataURL('image/jpeg');
          const pdf = new jsPDF('p', 'mm', 'a4');
          const imgWidth = 210; // A4 width in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight); // Adjust the dimensions as needed
    
          // Open the PDF in a new tab
          const pdfData = pdf.output('datauristring');
          const newTab = window.open();
          newTab.document.write('<iframe width="100%" height="100%" src="' + pdfData + '"></iframe>');
        });
      };

    return (
        <div className='about-container'>

            <div className='about-me-container'>
                <div className='img-container'><img src={pfp} alt='profile pic' className='profile-pic'></img></div>
                <div className='text-container'>
                    <p className='heading large'>Howdy!👋🏽 🤠<br/>I'm Pablo!</p>
                    <p className='about-me'>
                        I'm a Software Developer based in 📍Austin, TX! I graduated with a Bachelor of Science Degree in Information Sciences and Technology and a Smeal Business Certificate from Penn State. <br />
                        <br />
                        I enjoy coding beautiful and responsive websites. I have a passion for designing and creating websites that help bridge the gap between people and technology! 🤝<br />
                        <br />
                        Please feel free to reach out to me on linkedIn or via email! {'(links are in the footer)'} 👇🏽 
                    </p>
                </div>
            </div>
            <div className='skills row'>
                <Skills />
            </div>
            <div className='resume-container'>
                <img src={resume} alt='resume' className='resume-pic'></img>
            </div>
            <button onClick={downloadResume} className='download-button'>Download Resume</button>
        </div>
    );
}

export default About;