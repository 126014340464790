import React from 'react';
import { FaLinkedin, FaEnvelope } from 'react-icons/fa';
import './footer.scss';

function Footer() {
    const linkedinURL = 'https://www.linkedin.com/in/pablo-torres-950084240/'; // Replace with your LinkedIn profile URL
    const email = 'pabloytorresportfolio@gmail.com'; // Replace with your email address

    const openLinkedinProfile = () => {
        window.open(linkedinURL, '_blank');
    };

    const openEmailClient = () => {
        window.location.href = `mailto:${email}`;
    };
    return (
        <div className='footer'>
            <p className='small'>&copy;2024 Designed and Coded by Pablo Torres 👨🏽‍💻</p>
            <div className='social-icons'>
                <a href={linkedinURL} target='_blank' rel='noopener noreferrer' onClick={openLinkedinProfile}>
                    <div id='linked-in'><FaLinkedin size='1.5em' /></div>
                </a>
                <a href={`mailto:${email}`} onClick={openEmailClient}>
                    <div id='envelope'><FaEnvelope size='1.5em' /></div>
                </a>
            </div>
        </div>
    )
}

export default Footer;